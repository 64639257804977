import { Resolve } from '@angular/router';
import { HttpServerService } from '../http-server/http-server.service';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class PriceDateResolver implements Resolve<any> {

    constructor(@Inject(PLATFORM_ID) private platformId: Object,private httpServerService: HttpServerService){

    }

    resolve(){
        return isPlatformBrowser(this.platformId) ? this.httpServerService.getPriceDate() : null;
    }

}