import { BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpServerService } from './http-server/http-server.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule} from '@angular/forms'
import { AgmCoreModule } from '@agm/core';
import { AppModule } from './app.module';

@NgModule({
  imports: [
    
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBnsDukXv-wR8oePVOUQuUq9DsgVlxwZyE'
    }),
    AppModule,
    BrowserTransferStateModule
  ],
  providers: [HttpServerService],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
