import { BrowserModule } from '@angular/platform-browser';
import { NgModule, PLATFORM_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';

import { IzbeljivanjeZubaComponent } from './izbeljivanje-zuba/izbeljivanje-zuba.component';
import { KruniceIZubniMostoviComponent } from './krunice-i-zubni-mostovi/krunice-i-zubni-mostovi.component';
import { LecenjeZubaComponent } from './lecenje-zuba/lecenje-zuba.component';
import { OralnaHirurgijaComponent } from './oralna-hirurgija/oralna-hirurgija.component';
import { ParadontologijaComponent } from './paradontologija/paradontologija.component';
import { HttpServerService } from './http-server/http-server.service';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule} from '@angular/forms'
import { AgmCoreModule } from '@agm/core';
import { Error404Component } from './errors/404.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { PriceDateResolver } from './resolver/price-date-resolver.service';
import { LoginComponent } from './login/login.component';
import { AuthenticationService } from './auth/authentication.service';
import { AuthGaurdService } from './auth/auth-gaurd.service';

import { LogoutComponent } from './logout/logout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { 
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule
} from '@angular/material';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { RoleGuardService } from './auth/role-gaurd.service';


export function jwtOptionsFactory(platformId) {
  return {
    tokenGetter: () => {
      let token = null;
      if (isPlatformBrowser(platformId)) {
        token = sessionStorage.getItem('token');
      }
      return token;
    }
  };
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IzbeljivanjeZubaComponent,
    KruniceIZubniMostoviComponent,
    LecenjeZubaComponent,
    OralnaHirurgijaComponent,
    ParadontologijaComponent,
    Error404Component,
    LoginComponent,
    LogoutComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBnsDukXv-wR8oePVOUQuUq9DsgVlxwZyE'
    }),
    CommonModule,
    TransferHttpCacheModule,
    NgtUniversalModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MatInputModule,
    MatDatepickerModule,
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [PLATFORM_ID]
      }
    },
   )
  ],
  providers: [HttpServerService, PriceDateResolver, AuthenticationService, AuthGaurdService,RoleGuardService],
  bootstrap: [AppComponent]
})
export class AppModule { }
