import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-krunice-i-zubni-mostovi',
  templateUrl: './krunice-i-zubni-mostovi.component.html',
  styleUrls: ['./krunice-i-zubni-mostovi.component.css']
})
export class KruniceIZubniMostoviComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
