import { Injectable } from '@angular/core';
import { 
  Router,
  CanActivate,
  ActivatedRouteSnapshot
} from '@angular/router';

import decode from 'jwt-decode';
import { AuthenticationService } from './authentication.service';
@Injectable()
export class RoleGuardService implements CanActivate {
  constructor(public auth: AuthenticationService, public router: Router) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config
    // on the data property
    const expectedPermission = route.data.expectedPermission;
    const token = sessionStorage.getItem('token');
    // decode the token to get its payload
    const tokenPayload = decode(token);
    if (
      !this.auth.isAuthenticated()  || !tokenPayload.permissions.find(p => p === expectedPermission)
    ) {
        this.router.navigate(['prijava']);
      return false;
    }
    return true;
  }
}