import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Patient } from '../patient/patient.shared';


@Injectable()
export class HttpServerService{

    
    constructor(private http: HttpClient){}

    getPriceDate():Observable<IPriceDate>{
        return this.http.get<IPriceDate>('/api/price-date');
    }

    sendEmail(message:IMessage):Observable<IMessage>{
        return this.http.post<IMessage>('/api/send',message); 
    }

    savePatient(patient:Patient){
      return this.http.post<Patient>('/api/patient',patient); 
    }
   
    getPatients(){
        return this.http.get<Patient[]>('/api/patient'); 
      }

    getPatient(id):Observable<Patient>{
    return this.http.get<Patient>(`/api/patient/${id}`); 
    }

}

export interface IMessage{
    name:string; 
    email:string;
    subject:string;
    message:string;
}

export interface IPriceDate{    
    fromDate:string;
    toDate:string;
}