import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';

import { HttpServerService, IPriceDate, IMessage } from '../http-server/http-server.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{
  slideIndex;
  priceData: IPriceDate;
  name;
  email;
  subject;
  message;
  constructor(
  private  httpServerService:HttpServerService
   ) {  }
  
  ngOnInit() {
    this.priceData =  this.getMonthBoundaries();
  }
  
  sendEmail(message:IMessage){
    this.httpServerService.sendEmail(message).subscribe();
    setTimeout(function(){  location.reload(); }, 1000);
   
   
  
  }


   getMonthBoundaries(): IPriceDate {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();

    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);

    const pad = (num: number) => num.toString().padStart(2, '0');

    return {
        fromDate: `${pad(firstDay.getDate())}.${pad(firstDay.getMonth() + 1)}.${firstDay.getFullYear()}`,
        toDate: `${pad(lastDay.getDate())}.${pad(lastDay.getMonth() + 1)}.${lastDay.getFullYear()}`
    };
}

 

  openModal() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      return;
    }
    document.getElementById('global-nav').style.visibility = 'hidden';
    //this.$('#global-nav').css({ "visibility": "hidden" });
    document.getElementById('myModal').style.display = "block";
   // this.$('.myModal').css({ "border-width": "0px" });
  }

  closeModal() {
    document.getElementById('global-nav').style.visibility = 'visible';
    //this.$('#global-nav').css({ "visibility": "visible" });
    document.getElementById('myModal').style.display = "none";
  }
  



plusSlides(n) {
  this.showSlides(this.slideIndex += n);
}

 currentSlide(n) {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return;
  }
  this.showSlides(this.slideIndex = n);
}

 showSlides(n) {
  var i;
  var slides = document.getElementsByClassName("mySlides") as HTMLCollectionOf<HTMLElement>;
  
  if (n > slides.length) { this.slideIndex = 1 }
  if (n < 1) { this.slideIndex = slides.length }
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  slides[this.slideIndex - 1].style.display = "block";
 

}

}
