import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LecenjeZubaComponent } from './lecenje-zuba/lecenje-zuba.component';
import { ParadontologijaComponent } from './paradontologija/paradontologija.component';
import { OralnaHirurgijaComponent } from './oralna-hirurgija/oralna-hirurgija.component';
import { KruniceIZubniMostoviComponent } from './krunice-i-zubni-mostovi/krunice-i-zubni-mostovi.component';
import { IzbeljivanjeZubaComponent } from './izbeljivanje-zuba/izbeljivanje-zuba.component';
import { Error404Component } from './errors/404.component';
import { PriceDateResolver } from './resolver/price-date-resolver.service';
import { LoginComponent } from './login/login.component';
import { PatientComponent } from './patient/patient.component';
import { AuthGaurdService } from './auth/auth-gaurd.service';
import { LogoutComponent } from './logout/logout.component';
import { RoleGuardService } from './auth/role-gaurd.service';


const routes: Routes = [
  { path: '', component: HomeComponent ,data: { title: 'Stomatolog Novi Sad | Stomatološka ordinacija Marić' }},
  { path: 'pacijenti', loadChildren:'./patient/patient.module#PatientModule', data:{ title: 'Pacijenti', expectedPermission:'PATIENT_READ' },canActivate:[RoleGuardService]},
  { path: 'prijava', component: LoginComponent, data:{ title: 'Login' }},
  { path: 'odjava', component: LogoutComponent,canActivate:[AuthGaurdService] },
  { path: 'pocetna', redirectTo:'/#section-pocetna',data: { title: 'Stomatolog Novi Sad | Stomatološka ordinacija Marić' } },
  { path: 'usluge', redirectTo:'/#section-usluge' ,data: { title: 'Stomatolog Novi Sad | Stomatološka ordinacija Marić' }},
  { path: 'o-nama', redirectTo:'/#section-o-nama' ,data: { title: 'Stomatolog Novi Sad | Stomatološka ordinacija Marić' }},
  { path: 'galerija', redirectTo:'/#section-galerija',data: { title: 'Stomatolog Novi Sad | Stomatološka ordinacija Marić' } },
  { path: 'kontakt', redirectTo:'/#section-kontakt' ,data: { title: 'Stomatolog Novi Sad | Stomatološka ordinacija Marić' }},
  { path: 'cenovnik', redirectTo:'/#section-cenovnik' ,data: { title: 'Stomatolog Novi Sad | Stomatološka ordinacija Marić' }},

  { path: 'lecenje-zuba', component: LecenjeZubaComponent ,data: { title: 'Lečenje zuba' }},
  { path: 'izbeljivanje-zuba', component: IzbeljivanjeZubaComponent ,data: { title: 'Izbeljivanje zuba' }},
  { path: 'oralna-hirurgija', component: OralnaHirurgijaComponent ,data: { title: 'Oralna hirurgija' }},
  { path: 'paradontologija', component: ParadontologijaComponent ,data: { title: 'Paradontologija' }},
  { path: 'krunice-i-zubni-mostovi', component: KruniceIZubniMostoviComponent ,data: { title: 'Krunice i zubni mostovi' }},
  { path: '404', component: Error404Component },
  { path: '**', component: Error404Component }
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'disabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
