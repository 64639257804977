import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';
import { isPlatformBrowser } from '@angular/common';
export class User {
  constructor(
    public status: string,
  ) { }

}

@Injectable()
export class AuthenticationService {

  constructor(
    private httpClient: HttpClient,
    public jwtHelper: JwtHelperService,@Inject(PLATFORM_ID) private platform: Object) {
  }

  authenticate(username, password) {
    return this.httpClient.post<any>('/api/authenticate',{username,password}).pipe(
     map(
       userData => {
        if (isPlatformBrowser(this.platform)) {
        sessionStorage.setItem('token', userData.token);
        }
        return userData;
       }
     )

    );
  }

  isAuthenticated() {
    if (isPlatformBrowser(this.platform)) {
    const token = sessionStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
    
  }

  logOut() {
    if (isPlatformBrowser(this.platform)) {
    sessionStorage.removeItem('token');
    }
  }
}